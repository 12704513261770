<template>
  <div>
    <!-- 筛选层 -->
    <van-cell-group inset>
      <!-- 父级栏目 -->
      <div class="flx">
        <div style="width: 50%">
          <van-field
            v-model="parentText"
            is-link
            readonly
            name="picker"
            label="所属栏目"
            placeholder="所属栏目"
            @click="showParent = true"
          />
        </div>
        <div style="width: 50%">
          <!-- 资讯类型 -->
          <van-field
            v-model="resultType"
            is-link
            readonly
            name="picker"
            label="资讯类型"
            placeholder="资讯类型"
            @click="showType = true"
          />
        </div>
      </div>
      <!-- 选择 -->
      <!-- 资讯标题输入 -->
      <van-search
        v-model="TitleInformation"
        shape="round"
        placeholder="请输入搜索关键词"
      />
      <!-- 提交 -->
      <div class="flx7" style="width: 90%; margin: 0 auto">
        <van-button type="primary" size="small" @click="inquireClick">
          查询
        </van-button>
      </div>
      <!--  -->
      <div style="width: 100%; height: 0.2rem"></div>
    </van-cell-group>
    <div class="listStyle" v-for="(item, index) in issueList" :key="index">
      <div class="flx headerStyle">
        <div>资讯标题</div>
        <div>{{ item.title }}</div>
      </div>
      <div class="flx headerStyle">
        <div>所属栏目</div>
        <div>{{ item.navigatorName }}</div>
      </div>
      <div class="flx headerStyle">
        <div>资讯类型</div>
        <div>{{ item.type }}</div>
      </div>
      <div class="flx headerStyle">
        <div>编辑人</div>
        <div>{{ item.updatedBy }}</div>
      </div>
      <div class="flx headerStyle">
        <div>编辑时间</div>
        <div>{{ item.updatedAt }}</div>
      </div>
      <div class="flx headerStyle">
        <div>资讯状态</div>
        <div>{{ item.status }}</div>
      </div>
      <div class="flx headerStyle">
        <div>审核时间</div>
        <div>{{ item.examDate }}</div>
      </div>
    </div>
    <van-list
      v-model:loading="pages.loading"
      :finished="pages.finished"
      finished-text="没有更多了"
      @load="datasj"
    ></van-list>
    <!-- 栏目列表 -->
    <van-popup v-model:show="showType" position="bottom">
      <van-picker
        :columns="columnsList"
        @confirm="onConfirmType"
        @cancel="showType = false"
        :columns-field-names="columnsstyle"
      />
    </van-popup>
    <!-- 父级栏目 -->
    <van-popup
      v-model:show="showParent"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div style="width: 95%; margin: 0 auto">
        <div class="flx" style="height: 40px; line-height: 40px">
          <div
            style="color: var(--van-picker-cancel-action-color)"
            @click="showParent = false"
          >
            取消
          </div>
          <div
            style="color: var(--van-picker-confirm-action-color)"
            @click="affirm"
          >
            确认
          </div>
        </div>
        <div style="margin-top: 0.2rem">
          <van-radio-group
            v-for="(item, index) in ArrayList"
            :key="index"
            v-model="checked"
          >
            <div style="margin-top: 0.2rem">
              <van-radio :name="item.id" @click="Multiple(item)">
                {{ item.navName }}
              </van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import {
    ref,
    getCurrentInstance,
    onMounted,
    onUpdated,
    provide,
    reactive,
    watch,
    toRefs,
  } from "vue";
  import { Toast } from "vant";
  export default {
    setup() {
      const { proxy } = getCurrentInstance();
      const reactiveParams = reactive({
        checked: "",
        showNavigation: false,
        showType: false,
        showParent: false,
        columns: [],
        columnsList: JSON.parse(sessionStorage.getItem("configuration"))
          .informationType,
        columnsstyle: { text: "name" },
        resultType: "",
        parentText: "",
        resultTypeValue: "",
        headlineText: "",
        ArrayList: [],
        TitleInformation: "",
        data: {
          pi: 0,
          ps: 20,
          navigatorId: "",
          type: "",
          status: "",
          title: "",
        },
        issueList: [],
        MultipleValue: "",
        listState: false,
        pages: {
          loading: false,
          finished: false,
          totalPage: 0,
        },
      });
      const onConfirmType = (e) => {
        reactiveParams.data.type = e.value;
        reactiveParams.resultType = e.name;
        datasj();
        reactiveParams.showType = false;
      };
      const affirm = (e) => {
        reactiveParams.data.navigatorId = reactiveParams.MultipleValue.id;
        reactiveParams.parentText = reactiveParams.MultipleValue.navName;
        reactiveParams.showParent = false;
        datasj();
      };
      const Multiple = (e) => {
        reactiveParams.MultipleValue = e;
      };
      const issueData = async () => {
        let list = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getRoleSwitchInfo,
          {
            c: 1,
          }
        );
        list.data.data.forEach((item) => {
          console.log(item);
        });
        reactiveParams.ArrayList = list.data.data;
      };
      const datasj = async () => {
        if (reactiveParams.data.pi <= reactiveParams.pages.totalPage) {
          reactiveParams.data.pi = reactiveParams.data.pi + 1;
        } else {
          reactiveParams.data.pi = 0;
          reactiveParams.pages.loading = false;
          reactiveParams.pages.finished = true;
          return;
        }
        let list = await proxy.$Api.get(proxy.$generalApi.getPage, {
          pi: reactiveParams.data.pi,
          ps: reactiveParams.data.ps,
          navigatorId: reactiveParams.data.navigatorId,
          type: reactiveParams.data.type,
          status: reactiveParams.data.status,
          title: reactiveParams.data.title,
        });
        if (reactiveParams.data.pi == list.data.data.totalPage) {
          reactiveParams.pages.finished = true;
        } else {
          reactiveParams.pages.totalPage = list.data.data.totalPage;
        }
        if (list.data.data.length == 0) {
          reactiveParams.listState = true;
          return;
        }

        list.data.data.data.forEach((item) => {
          if (item.type == 1) {
            item.type = "图文资讯";
          } else {
            item.type = "外链资讯";
          }
          if (item.status == 1) {
            item.staus = "";
          } else if (item.status == 2) {
            item.status = "";
          } else if (item.status == 3) {
            item.status = "已发布";
          }
        });
        reactiveParams.issueList = list.data.data.data;
        console.log(reactiveParams.issueList);
      };
      const inquireClick = () => {
        reactiveParams.data.title = reactiveParams.TitleInformation;
        datasj();
      };
      onMounted(() => {
        issueData();
      });
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onConfirmType,
        affirm,
        Multiple,
        inquireClick,
        datasj,
      };
    },
  };
</script>
<style scoped>
  .listStyle {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .headerStyle {
    margin-top: 0.1rem;
  }
  div /deep/ .van-cell {
    padding: 10px;
  }
  div /deep/ .van-field__label {
    width: 1.3rem !important;
    margin-right: 0.1rem !important;
  }
</style>
